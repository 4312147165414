import React from "react"
import styled, { css } from "styled-components"
import CSS from "../config/css"

import imageShopNow from "../images/homepage/box-logo-shop-online-text.svg"
import imageShopNowBg from "../images/homepage/box-bg-shop.jpg"

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: ${CSS.rowWidth};
  margin-right: auto;
  margin-left: auto;
`

const Box = styled.div`
  flex: 1 1 calc(33% - 30px); // 250px
  margin: ${CSS.margin};
  padding: 30px;
  background: ${CSS.colors.white};
  border: 1px solid #ddd;
  box-shadow: 10px 10px 0 rgba(51, 51, 51, 0.3);
  text-align: center;
  .title {
    margin-top: 0;
    font: 400 36px/40px ${CSS.fonts.script};
  }

  &.gold {
    box-shadow: 10px 10px 0 rgba(${CSS.colors.goldrgb}, 0.6);
  }

  &.shop {
    background: white url(${imageShopNowBg}) bottom center no-repeat;
    background-size: cover;
    border: none;
    a {
      display: inline-block;
      background: ${CSS.colors.white};
      border: 3px solid ${CSS.colors.darkgrey};
      border-radius: 1000px;
      width: 200px;
      height: 200px;
    }
  }
`

export { BoxRow, Box }

import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Link from "../components/link"
import Layout from "../components/layout"
import styled, { css } from "styled-components"
import { Row, Shim } from "../components/row"
import { ButtonLink } from "../components/button"
import { BoxRow, Box } from "../components/box"
import SEO from "../components/seo"
import CSS from "../config/css"

// Images
import imageLogoPCA from "../images/homepage/box-pca-logo.png"
import imageShopNow from "../images/homepage/box-logo-shop-online-text.svg"
import imageDotsLightBg from "../images/ui/bg_dots_light.png"

const pageTitle = "Home"

const IntroH2 = styled.h2`
  font: 60px ${CSS.fonts.script};
  span {
    display: block;
    font: 200 27px ${CSS.fonts.body};
  }
`

const FeatureWrapper = styled(Row)`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 700px) {
    flex-direction: row;
  }
`

const FeatureSet = styled.div`
  flex: 1 1 calc(33.3% - 10px);
  margin-left: 0;

  @media only screen and (min-width: 700px) {
    margin-left: 20px;
    ${props =>
      props.main &&
      css`
        flex: 1 1 66.6%;
        margin-left: 0;
      `}
  }
`

const FeatureLabel = styled.div`
  position: absolute;
  display: block;
  z-index: 1;
  padding: 20px 15px;
  background-color: #aa9c6a;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
  font-family: "Dancing Script", "Brush Script MT", cursive;
  font-size: 30px;
  color: #fff;
  text-align: center;
  span {
    display: block;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 200;
    font-family: "Raleway", "Century Gothic", Helvetica, Arial, sans-serif;
  }
  &.top {
    top: -5px;
    left: calc(50% - 75px);
    width: 150px;
    padding: 10px 0;
  }
  &.right {
    top: calc(60% - 40px);
    right: -5px;
    width: 150px;
    height: 80px;
    box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.3);
  }
  &.left {
    width: 150px;
    height: 80px;
    top: calc(75% - 60px);
    left: -5px;
  }
  &.red {
    background-color: #c51c1c;
  }
  &.pink {
  }
  &.wide {
    width: 200px;
  }
  &.wwide {
    width: 260px;
  }
  &.tall {
    height: 110px;
  }
`

const FeatureLink = styled(Link)`
  position: relative;
  display: block;
  margin-bottom: 20px;
`

const FeatureItem = props => (
  <FeatureLink to={props.dest}>
    <FeatureLabel
      className={props.labelClass}
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
    <Img fluid={props.img} alt={props.imgAlt} />
  </FeatureLink>
)

class Index extends React.Component {
  render() {
    const featureImages = this.props.data.allFile.edges
    return (
      <Layout>
        <FeatureWrapper>
          <FeatureSet main>
            <FeatureItem
              labelClass="top"
              dest="cakes"
              img={featureImages[0].node.childImageSharp.fluid}
              imgAlt="Gold foil wedding cakes"
              text="Weddings <span>&amp; cakes</span>"
            />
          </FeatureSet>
          <FeatureSet>
            <FeatureItem
              labelClass="right"
              dest="cupcakes"
              img={featureImages[1].node.childImageSharp.fluid}
              imgAlt="Snickers bar fudge cupcake"
              text="Cupcakes"
            />

            <FeatureItem
              labelClass="left"
              dest="desserts"
              img={featureImages[2].node.childImageSharp.fluid}
              imgAlt="Tiramisu cupcake shooters"
              text="Desserts"
            />

            {/*<FeatureItem
             labelClass="left tall red"
             dest="https://store.frostedcakery.com/shop/4th-of-july/16"
             img={featureImages[3].node.childImageSharp.fluid}
             imgAlt="American Flag Sprinkle Cake"
             text="4th of July<span>desserts</span>"
            />*/}

          </FeatureSet>
        </FeatureWrapper>

        <div
          style={{
            background: `transparent url(${imageDotsLightBg}) center 200px repeat-x`,
            backgroundSize: "450px",
          }}
        >
          <Row>
            <Shim>
              <IntroH2>
                <span>Dreams in</span> Buttercream
              </IntroH2>
              <p>
                We make delicious dreams come to life. Our cakes, cupcakes &amp;
                desserts are more than just a simple confection, they're a
                symbol of celebration and art.
              </p>
              <p>
                <ButtonLink to="/about" data-title="Our Story">
                  Our Story
                </ButtonLink>
                <ButtonLink
                  darkgold
                  to="https://store.frostedcakery.com/s/appointments"
                  data-title="Book a consultation"
                >
                  Book a consultation
                </ButtonLink>
              </p>
            </Shim>
          </Row>
          <BoxRow>
            <Box className="gold">
              <img src={imageLogoPCA} alt="People's Choice Awards" />
              <p
                style={{
                  margin: "0px",
                  font: `20px/30px ${CSS.fonts.script}`,
                }}
              >
                2012 2013 2014 2015 2016
                <br />
                2017 2018 2019 2020 2022
              </p>
            </Box>
            <Box className="shop">
              <a href="https://store.frostedcakery.com">
                <img src={imageShopNow} alt="Shop now" />
              </a>
            </Box>
            <Box className="gold">
              <h3 className="title">At our Counter</h3>
              <p>View our weekly menu or call</p>
              <p style={{ fontSize: `14px`}}>
                Tower District: (559) 917-8880<br />
                Bullard & West: (559) 455-8979
              </p>
              <ButtonLink darkgold to="menu" data-title="This week's menu">
                This week's menu
              </ButtonLink>
            </Box>
          </BoxRow>
        </div>
      </Layout>
    )
  }
}

export default Index

export const featureQuery = graphql`
  query FeatureImages {
    allFile(
      filter: { relativePath: { regex: "/feature-/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

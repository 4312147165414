import React from "react"
import Link from "./link"
import styled, { css } from "styled-components"
import CSS from "../config/css"

import imageLineDiag from "../images/ui/line-diag.png"

const ButtonLink = styled(Link)`
  position: relative;
  display: inline-block;
  background: transparent url(${imageLineDiag}) 0 0 repeat;
  background-size: 4px;
  margin: ${CSS.margin} 10px;
  padding: 10px ${CSS.margin};
  font-size: 15px;
  line-height: 1.15;
  text-transform: uppercase;
  :after {
    position: absolute;
    top: -10px;
    left: -10px;
    content: attr(data-title);
    width: 100%;
    height: 100%;
    background-color: ${CSS.colors.white};
    border: 2px ${CSS.colors.darkgrey} solid;
    color: ${CSS.colors.darkgrey};
    text-align: center;
    line-height: 37px;
  }
  :hover,
  :focus {
    :after {
      top: -11px;
      left: -11px;
    }
  }
  ${props =>
    props.mint &&
    css`
      :after {
        background-color: ${CSS.colors.mint};
        border-color: ${CSS.colors.mint};
        color: ${CSS.colors.white};
      }
    `}
  ${props =>
    props.gold &&
    css`
      :after {
        background-color: ${CSS.colors.gold};
        border-color: ${CSS.colors.gold};
        color: #fff;
      }
    `}
  ${props =>
    props.darkgold &&
    css`
      :after {
        background-color: ${CSS.colors.darkgold};
        border-color: ${CSS.colors.darkgold};
        color: ${CSS.colors.white};
      }
    `}
  ${props =>
    props.darkergold &&
    css`
      :after {
        background-color: ${CSS.colors.darkergold};
        border-color: ${CSS.colors.darkergold};
        color: ${CSS.colors.white};
      }
    `}
`

const SubmitButton = styled.button`
  position: relative;
  display: inline-block;
  background: transparent url(${imageLineDiag}) 0 0 repeat;
  background-size: 4px;
  margin: ${CSS.margin} 10px;
  padding: 10px ${CSS.margin};
  font-size: 15px;
  line-height: 1.15;
  text-transform: uppercase;
  :after {
    position: absolute;
    top: -10px;
    left: -10px;
    content: attr(data-title);
    width: 100%;
    height: 100%;
    background-color: ${CSS.colors.darkgold};
    border: 2px ${CSS.colors.darkgold} solid;
    color: ${CSS.colors.white};
    text-align: center;
    line-height: 37px;
  }
  :hover,
  :focus {
    :after {
      top: -11px;
      left: -11px;
    }
  }
`

export { ButtonLink, SubmitButton }
